import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
body {
    color: #000;
    background-color: #fff;
    font-family: Avenir Next, sans-serif;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}
`
