import { Link as GatsbyLink } from 'gatsby'
import styled from 'styled-components'

export default styled(GatsbyLink)`
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: currentColor;
  text-transform: uppercase;
`
