import PropTypes from 'prop-types'
import React from 'react'
import { Flex, Box, Text } from 'rebass/styled-components'
import Link from 'components/Link'
import { useShopContext } from 'context/provider'

const Header = ({ siteTitle }) => {
  const { state } = useShopContext()

  const totalQuantity = state.cart.reduce((acc, cur) => {
    return acc + cur.quantity
  }, 0)

  return (
    <Box
      as="header"
      py={3}
      position="sticky"
      top={0}
      zIndex={200}
      color="#fff"
      bg="#363636">
      <Flex as="nav" alignItems="center" maxWidth="1040px" mx="auto" px={3}>
        <Text color="#fff" fontSize={2}>
          <Link to="/">{siteTitle}</Link>
        </Text>
        <Box mx="auto"></Box>
        <Flex>
          <Box ml={4}>
            <Link to="/">Home</Link>
          </Box>
          <Box ml={4}>
            <Link to="/products">Shop</Link>
          </Box>
          <Box ml={4}>
            <Link to="/cart">Cart ({totalQuantity})</Link>
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
