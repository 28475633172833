import React from 'react'
import Link from 'components/Link'
import { Flex, Box, Text } from 'rebass/styled-components'

const Footer = () => {
  return (
    <Box as="footer" width={1} color="#fff" bg="#363636" py={3}>
      <Flex maxWidth="1040px" mx="auto" justifyContent="space-evenly">
        <Link to="/">
          <Text fontSize={2}>Home</Text>
        </Link>
        <Link to="/">
          <Text fontSize={2}>Connect</Text>
        </Link>
        <Link to="/products">
          <Text fontSize={2}>Shop</Text>
        </Link>
        <Text fontSize={2}>© Chi Hou Yeung</Text>
      </Flex>
    </Box>
  )
}

export default Footer
